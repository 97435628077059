import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import styled from 'styled-components';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../hooks/useAuth';
import useMedia from '../../hooks/useMedia';
import { Loading } from '../../components/Loading';
import Header from '../../components/Header';
import Container from '../../components/Container';
import Content from '../../components/Section/Content';
import Icon from '../../components/Section/Icon';
import svg from '../../static/img/icon-open-menu.svg';
import AddressForm from './AddressForm/AddressForm';
import PhoneForm from './PhoneForm/PhoneForm';
import RegistrationDataForm from './RegistrationDataForm/RegistrationDataForm';
import InternationalPurchaseAddressForm from './InternationalPurchaseAddressForm/InternationalPurchaseAddressForm';
import Button from '../../components/Button';
import Title from '../../components/Title';
import { api } from '../../services/api';
import {
  Address,
  ExternalValidation,
  Phones,
  UserData,
} from '../../types/userData';
import inputMask, { maskCEP } from '../../util/mask';
import { isValidCNPJ, validateCPFCNPJ } from '../../util/validation';
import Label from '../../components/Form/Label';
import Action from '../../components/Section/Action';
import { Actions, Categories } from '../../types/gtm';
import { scrollToErrors } from '../../util/scrollToErrors';
import sendFieldsChangedToGTM from '../../util/sendUserDataChangesToGTM';
import {
  INTERVENTION_MOBILE_PHONE,
  INTERVENTION_CONFIRM_MOBILE_PHONE,
  INTERVENTION_CPF,
  INTERVENTION_CONFIRM_CPF,
  INCONSISTENCY_MESSAGE,
} from '../../util/interventionsMessage';
import PopUpIntervention from '../../components/PopUpIntervention';
import { getDevice, getUserLocale } from '../../util/userInfo';

const FormContainerMobile = styled.div`
  width: 95%;
  min-height: 58vh;
  > form {
    div + label {
      width: 100%;
      margin: 0 0 16px 0;
    }
    > button {
      margin-top: 32px;
    }
  }
`;

const FormContainerWeb = styled.div`
  font-size: 14px;
  > button {
    margin-top: 32px;
  }
`;

const defaultValue = {
  fullName: '',
  docNumber: '',
  email: '',
  birthDate: '',
  gender: '',
  socialName: '',
  photoURL: '',
  address: {
    neighborhood: '',
    zipcode: '',
    address1: '',
    address2: '',
    addressType: '',
    number: 0,
    country: {
      id: 0,
      name: '',
    },
    city: {
      id: 0,
      name: '',
    },
    state: {
      id: 0,
      name: '',
      abbreviation: '',
    },
  },
  phones: {
    dddCellPhone: '',
    cellPhone: '',
    dddPhone: '',
    phone: '',
    phoneFull: '',
    cellPhoneFull: '',
    mobilePhoneConfirmed: false,
  },
  siscoserv: {
    nif: '',
    address: '',
    country: '',
  },
  isDocNumberLocked: false,
  hasIncosistentData: false,
};

export const validationSchema = Yup.object().shape({
  isdocNumberLocked: Yup.boolean(),
  hasIncosistentData: Yup.boolean(),
  fullName: Yup.string().when('isDocNumberLocked', {
    is: false,
    then: schema =>
      schema
        .required('Você precisa informar seu nome.')
        .min(0, 'Você precisa informar seu nome.')
        .min(4, 'Você deve informar um nome válido.')
        .matches(
          /^([a-zA-Zà-úÀ-Ú]{2,})+\s+([a-zA-Zà-úÀ-Ú\s.]{2,})+$/,
          'Você deve informar nome e sobrenome.',
        ),
  }),
  docNumber: Yup.string().when('isDocNumberLocked', {
    is: false,
    then: schema =>
      schema
        .matches(
          /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})/,
          `Você deve informar um CPF ou CNPJ válido.`,
        )
        .test(
          'validateDocNumber',
          `Você deve informar um CPF ou CNPJ válido.`,
          (value = '') => {
            if (validateCPFCNPJ(value)) {
              return true;
            }
            return false;
          },
        ),
  }),
  gender: Yup.string().required('Campo deve ser preenchido.'),
  birthDate: Yup.string().when('isDocNumberLocked', {
    is: false,
    then: schema =>
      schema
        .required('Campo deve ser preenchido.')
        .test('dob', `Você deve informar uma data válida.`, value => {
          return (
            moment(value, 'DD/MM/YYYY').isValid() &&
            moment(value, 'DD/MM/YYYY').isBefore(new Date(), 'day')
          );
        })
        .test('dobMinAge', `Idade Mínima: 13 anos.`, value => {
          const minAge = 13;
          const birthday = moment(value, 'DD.MM.YYYY');
          const age = moment.duration(moment().diff(birthday)).asYears();
          return age >= minAge;
        })
        .test('dobMaxAge', `Idade Máxima: 120 anos.`, value => {
          const maxAge = 120;
          const birthday = moment(value, 'DD.MM.YYYY');
          const age = moment.duration(moment().diff(birthday)).asYears();
          return age <= maxAge;
        })
        .matches(
          /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/,
          `Você deve informar uma data válida.`,
        ),
  }),
  phones: Yup.object().shape({
    phone: Yup.string().test(
      'len',
      'Você deve informar um telefone válido.',
      (value = '') => {
        if (value) {
          const phoneOnlyNumber = value
            .replace(' ', '')
            .replace('(', '')
            .replace(')', '')
            .replace('-', '');
          if (phoneOnlyNumber.length < 10 || phoneOnlyNumber.length > 11) {
            return false;
          }

          return true;
        }
        return true;
      },
    ),
    cellPhone: Yup.string()
      .required('Campo deve ser preenchido.')
      .test(
        'len',
        `Informe um número no formato válido. Exemplo: (99) 99999-9999`,
        (value = '') => {
          if (value) {
            const phoneOnlyNumber = value
              .replace(' ', '')
              .replace('(', '')
              .replace(')', '')
              .replace('-', '');
            if (
              phoneOnlyNumber.length <= 10 ||
              phoneOnlyNumber.length > 11 ||
              phoneOnlyNumber.charAt(2) !== '9'
            ) {
              return false;
            }

            return true;
          }
          return true;
        },
      ),
  }),
  address: Yup.object().shape({
    zipcode: Yup.string().test('validZipCode', 'Cep inválido.', (cep = '') => {
      if (cep && cep.length > 0) {
        return /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/.test(cep.trim());
      }
      return true;
    }),
    country: Yup.object().shape({
      name: Yup.string(),
    }),
    state: Yup.mixed().when('country.name', {
      is: 'Brasil',
      then: Yup.object().shape({
        name: Yup.string().required('O estado precisa ser preenchido.'),
      }),
    }),

    city: Yup.mixed().when('country.name', {
      is: 'Brasil',
      then: Yup.object().shape({
        name: Yup.string().required('A cidade precisa ser preenchida.'),
      }),
    }),
  }),
});

interface InterventionModel {
  type: string;
  title: string;
  description: string;
  redirectModal: string;
  dialogTitle: string;
  dialogDescription: string;
}

interface InterventionObject {
  interventionID: string;
  interventionName: string;
  interventionURL: string;
  type: string;
  fields: [key: string];
  message: string;
  validateExternal: boolean;
  validateMobilePhone: boolean;
}

const EditUserData = () => {
  const history = useHistory();
  const [userData, setUser] = useState<UserData>(defaultValue);
  const [loading, setLoading] = useState<boolean>();
  const [step, setStep] = useState<string>('');
  const { logout, user, authenticateOnAPI } = useAuth();
  const [interventionSelected, setInterventionSelected] =
    useState<InterventionModel>();

  const mobile = useMedia('(max-width: 650px)');

  const normalizeValues = (data: UserData) => {
    let values: UserData = {
      ...defaultValue,
      ...data,
    };

    let address: Address = {
      ...defaultValue.address,
    };

    if (data.address) {
      address = {
        ...address,
        ...data.address,
      };
      if (data.address.zipcode) {
        address = {
          ...data.address,
          zipcode: maskCEP(data.address.zipcode),
        };
      }
    }

    let phones: Phones = {
      ...defaultValue.phones,
    };

    if (data.phones) {
      phones = {
        ...phones,
        ...data.phones,
      };

      if (data.phones.phone) {
        phones = {
          ...data.phones,
          phone: `${data.phones.dddPhone}${data.phones.phone}`,
          dddPhone: '',
        };
      }

      if (data.phones.cellPhone) {
        phones = {
          ...data.phones,
          ...phones,
          cellPhone: `${data.phones.dddCellPhone}${data.phones.cellPhone}`,
          dddCellPhone: '',
        };
      }
    }

    values = {
      ...values,
      address,
      phones,
    };

    setUser(values);

    return userData;
  };

  const showDynamicIntervention = (interventions: any) => {
    if (interventions.length === 0) {
      setInterventionSelected({} as InterventionModel);
      return {} as InterventionModel;
    }

    const randomicIntervention = Math.floor(
      Math.random() * interventions.length,
    );
    setInterventionSelected(interventions[randomicIntervention]);
    return interventions[randomicIntervention];
  };

  const mountInterventionFields = (fieldsObject: InterventionObject): any => {
    const intervArray: any[] = [];
    const isMobileConfirmation = fieldsObject?.validateMobilePhone;
    const validateExternal = fieldsObject?.validateExternal;
    let showCPFIntervention = false;
    let showMobileIntervention = false;

    if (fieldsObject.fields) {
      for (let i = 0; i < fieldsObject.fields.length; i += 1) {
        if (fieldsObject.fields[i] === 'docs') {
          intervArray.push(INTERVENTION_CPF);
          showCPFIntervention = true;
        }
        if (fieldsObject.fields[i] === 'cellPhone') {
          intervArray.push(INTERVENTION_MOBILE_PHONE);
          showMobileIntervention = true;
        }
      }
    }

    if (validateExternal && !showCPFIntervention) {
      intervArray.push(INTERVENTION_CONFIRM_CPF);
    }

    if (isMobileConfirmation && !showMobileIntervention) {
      intervArray.push(INTERVENTION_CONFIRM_MOBILE_PHONE);
    }

    return showDynamicIntervention(intervArray);
  };

  const paramsGetIntervention = {
    device: getDevice(),
    country: getUserLocale(),
  };

  const getIntervention = (token: string) => {
    return api.get(`/user/intervention/${paramsGetIntervention.device}/BR`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUser = (token: string) => {
    return api.post('/user', null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const hasInconsistentData = (
    isDocNumberLocked: boolean,
    externalValidation?: ExternalValidation,
  ) => {
    const inconsistentData =
      !isDocNumberLocked &&
      externalValidation &&
      externalValidation.validationStatus === 'ExternalValidationNotPassed';
    return inconsistentData;
  };

  const isDocNumberLocked = (u: UserData) => {
    return (
      (u.externalValidation &&
        u.externalValidation.validationStatus === 'ExternalValidationPassed') ||
      false
    );
  };

  const init = async () => {
    const token = await authenticateOnAPI();
    Promise.all([getUser(token), getIntervention(token)])
      .then(function (results) {
        const getUserResponse = results[0] as AxiosResponse<UserData>;
        const getInternvetionResponse = results[1].data;
        const docNumberLocked = isDocNumberLocked(getUserResponse.data);
        const values: UserData = {
          ...getUserResponse.data,
          hasInconsistentData: hasInconsistentData(
            docNumberLocked,
            getUserResponse.data.externalValidation,
          ),
          isDocNumberLocked: docNumberLocked,
        };
        normalizeValues(values);
        mountInterventionFields(getInternvetionResponse);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error('Erro ao pegar dados do usuário');
      });
  };

  const onMobileOrDocNumberConfirm = async () => {
    init();
  };

  useEffect(() => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.EditUserPersonalData,
      event_action: Actions.Pageview,
      bd_suser_code: user?.globoId,
    });

    setLoading(true);
    init();
  }, []);

  const hasUnconfirmedDocNumber = (
    values: UserData,
    initialValues: UserData,
  ) => {
    if (
      initialValues.docNumber === values.docNumber &&
      isValidCNPJ(initialValues.docNumber)
    ) {
      return false;
    }
    if (
      !values.externalValidation ||
      values.externalValidation.validationStatus !== 'ExternalValidationPassed'
    ) {
      return true;
    }
    return false;
  };

  const handleFormSubmit = async (
    values: UserData,
    initialValues: UserData,
  ) => {
    const body = { ...values };

    if (!values.docNumber.length) {
      toast.error('Cadastre o seu CPF para continuar');
      return;
    }

    if (hasUnconfirmedDocNumber(values, initialValues)) {
      toast.error('Confirme o seu CPF para continuar');
      return;
    }

    if (initialValues === values) {
      toast.info('Nenhuma informação alterada para salvar');
      return;
    }

    if (body.address) {
      body.address.addressType = 'R';

      if (!body.address.number) {
        body.address.number = 0;
      }
    }

    if (body.phones && body.phones.phone) {
      const unmaskPhone = inputMask.unMask(body.phones.phone);
      body.phones.phone = unmaskPhone.substring(2, 16);
      body.phones.dddPhone = unmaskPhone.substring(0, 2);
    }

    const userToUpdate = {
      fullName: body.fullName,
      address: {
        ...(body.address.country.id === 1
          ? body.address
          : {
              country: {
                id: body.address.country.id,
                name: body.address.country.name,
              },
            }),
        zipcode: body.address.zipcode
          ? inputMask.unMask(body.address.zipcode)
          : '',
        address1: body.address.zipcode ? body.address.address1 : '',
        address2: body.address.zipcode ? body.address.address2 : '',
      },
      birthDate: body.birthDate,
      docNumber: inputMask.unMask(body.docNumber),
      gender: body.gender,
      socialName: body.socialName,
      phones: body.phones,
      siscoserv: body.siscoserv,
    };

    const token = await authenticateOnAPI();
    api
      .put('/user', userToUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (response.status === 200) {
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.EditUserPersonalData,
            event_action: Actions.ClickSaveChanges,
            bd_suser_code: user?.globoId,
          });

          sendFieldsChangedToGTM(body, initialValues, user?.globoId);
          toast.success('Seus dados foram alterados com sucesso');
          init();
        }
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao salvar os dados do usuário');
      });
  };

  if (loading) {
    return <Loading />;
  }

  const chooseStep = (string: string) => {
    switch (string) {
      case 'address':
        return <AddressForm title="Endereços" />;
      case 'phones':
        return (
          <PhoneForm
            title="Telefones"
            user={user}
            onMobilePhoneConfirmResult={onMobileOrDocNumberConfirm}
          />
        );
      case 'registrationData':
        return (
          <RegistrationDataForm
            title="Dados Cadastrais"
            onDocNumberConfirmResult={onMobileOrDocNumberConfirm}
            onDocNumberInconsistentResult={onMobileOrDocNumberConfirm}
          />
        );
      case 'internationalPurchaseAddress':
        return (
          <InternationalPurchaseAddressForm title="Endereço de compra internacional" />
        );
      default:
        return '';
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover={false}
      />

      <>
        <Header
          user={user}
          screenMenu={Categories.EditUserPersonalData}
          onLogout={logout}
          showReturn
          onReturn={() => {
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: Categories.EditUserPersonalData,
              event_action: Actions.ClickBack,
              bd_suser_code: user?.globoId,
            });

            if (step) {
              setStep('');
            } else {
              history.push('/');
            }
          }}
        />
        <Container>
          {interventionSelected?.type === INTERVENTION_MOBILE_PHONE.type &&
            !userData.hasInconsistentData && (
              <PopUpIntervention
                messageIntervention={INTERVENTION_MOBILE_PHONE}
                user={user}
                isBanner
              />
            )}
          {interventionSelected?.type === INTERVENTION_CPF.type &&
            !userData.hasInconsistentData && (
              <PopUpIntervention
                messageIntervention={INTERVENTION_CPF}
                user={user}
                isBanner
              />
            )}
          {interventionSelected?.type ===
            INTERVENTION_CONFIRM_MOBILE_PHONE.type &&
            !userData.hasInconsistentData && (
              <PopUpIntervention
                messageIntervention={INTERVENTION_CONFIRM_MOBILE_PHONE}
                user={user}
                isBanner
              />
            )}
          {interventionSelected?.type === INTERVENTION_CONFIRM_CPF.type &&
            !userData.hasInconsistentData && (
              <PopUpIntervention
                messageIntervention={INTERVENTION_CONFIRM_CPF}
                user={user}
                isBanner
              />
            )}
          {userData.hasInconsistentData && (
            <PopUpIntervention
              messageIntervention={INCONSISTENCY_MESSAGE}
              user={user}
              isBanner
              hasInconsistency
            />
          )}

          <Formik
            enableReinitialize
            initialValues={userData}
            validationSchema={validationSchema}
            onSubmit={values => {
              handleFormSubmit(values, userData);
            }}
          >
            {({ handleSubmit, errors }) => {
              return mobile ? (
                // mobile-menu
                <FormContainerMobile>
                  <form onSubmit={handleSubmit}>
                    {step ? (
                      <>
                        {chooseStep(step)}
                        <Button
                          onClick={() => scrollToErrors(errors)}
                          type="submit"
                          variant="secondary"
                          width="100%"
                        >
                          Salvar Alterações
                        </Button>
                      </>
                    ) : (
                      <>
                        <Title>Editar Dados Pessoais</Title>
                        <Label htmlFor="#">
                          Gerencie seus dados cadastrais e de acesso
                        </Label>
                        <Content>
                          <Action
                            theme="buttonActionList"
                            onClick={() => setStep('registrationData')}
                          >
                            <p>Dados cadastrais</p>
                            <Icon svg={svg} alt="icon-open-menu" />
                          </Action>

                          <hr aria-hidden />

                          <Action
                            theme="buttonActionList"
                            onClick={() => setStep('phones')}
                          >
                            <p>Telefones</p>
                            <Icon svg={svg} alt="icon-open-menu" />
                          </Action>

                          <hr aria-hidden />

                          <Action
                            theme="buttonActionList"
                            onClick={() => setStep('address')}
                          >
                            <p>Endereços</p>
                            <Icon svg={svg} alt="icon-open-menu" />
                          </Action>

                          {userData.siscoserv !== undefined &&
                            (userData.siscoserv?.country ||
                              userData.siscoserv?.address) && (
                              <>
                                <hr aria-hidden />
                                <Action
                                  theme="buttonActionList"
                                  onClick={() =>
                                    setStep('internationalPurchaseAddress')
                                  }
                                >
                                  <p>Endereço de compra internacional</p>
                                  <Icon svg={svg} alt="icon-open-menu" />
                                </Action>
                              </>
                            )}
                        </Content>
                      </>
                    )}
                  </form>
                </FormContainerMobile>
              ) : (
                // web-menu
                <form onSubmit={handleSubmit}>
                  <FormContainerWeb>
                    <RegistrationDataForm
                      title="Dados Cadastrais"
                      onDocNumberConfirmResult={onMobileOrDocNumberConfirm}
                      onDocNumberInconsistentResult={onMobileOrDocNumberConfirm}
                    />
                    <PhoneForm
                      title="Telefones"
                      user={user}
                      onMobilePhoneConfirmResult={onMobileOrDocNumberConfirm}
                    />
                    <AddressForm title="Endereços" />
                    <InternationalPurchaseAddressForm title="Endereço de compra internacional" />
                    <Button
                      onClick={() => {
                        scrollToErrors(errors);
                      }}
                      type="submit"
                      variant="secondary"
                      width="100%"
                    >
                      Salvar Alterações
                    </Button>
                  </FormContainerWeb>
                </form>
              );
            }}
          </Formik>
        </Container>
      </>
    </>
  );
};

export default EditUserData;
