export type UnderageUser = {
  underageId: string;
  canBeManaged: boolean;
  email: string;
  name: string;
  status: number;
  authorizationAt?: string;
  blockedAt?: string;
  // eslint-disable-next-line camelcase
  blocked_until?: string;
  approvalDeadline?: string;
  hash?: string;
};

export enum UnderageUserStatus {
  Unblocked = 1,
  Blocked = 4,
}
