type ENVIRONMENT_TYPE = {
  [env: string]: {
    ACCOUNT_MANAGEMENT_URL: string;
    API_BASE_URL: string;
    REDIRECT_URL: string;
    COMPONENTS_URL: string;
    LOGOUT_URL: string;
    FAMILY_URL: string;
    FACEBOOK_APP_ID: string;
    FORGOT_PASSWORD_URL: string;
    GLOBO_COM_URL: string;
    GOOGLE_CLIENT_ID: string;
    GOOGLE_RECAPTCHA_KEY: string;
    GOOGLE_ANALYTICS: string;
    ASSIGNATURE_URL: string;
    ASSIGNATURE_DETAIL_URL: string;
    PARENTAL_CONSENT_SERVICE_LOGIN_PAGE: string;
    ORIGIN_ID: number;
    MY_FAMILY_URL: string;
    LGPD_LIB: string;
    LGPD_LIB_CSS: string;
    AVATAR_ID_URL: string;
  };
};

const redirectUrl = window.location.origin;

const ENVIRONMENT: ENVIRONMENT_TYPE = {
  local: {
    ACCOUNT_MANAGEMENT_URL: 'http://minha-conta-v2.local.globoi.com:3000',
    API_BASE_URL: 'http://minha-conta-v2.local.globoi.com:8888',
    REDIRECT_URL:
      'https://id.qa.globoi.com/auth/realms/globo.com/protocol/openid-connect/auth?client_id=minha-conta-qa-v2@apps.globoid&redirect_uri=http://minha-conta-v2.local.globoi.com:3000&response_type=code&scope=openid+email+profile-min&prompt=login',
    COMPONENTS_URL:
      'https://s3.glbimg.com/v1/AUTH_1e4d71a8f6ee47a9aca424bf41449167/globoid-components-container/components.min.js',
    LOGOUT_URL: 'https://login.qa.globoi.com/logout?url=https://www.globo.com',
    FAMILY_URL: 'https://globoid-family-api.qa.globoi.com',
    FACEBOOK_APP_ID: '1611735559115528',
    FORGOT_PASSWORD_URL: 'https://login.dev.globoi.com/recuperacaoSenha/7030',
    GLOBO_COM_URL: 'https://www.globo.com',
    GOOGLE_CLIENT_ID:
      '405592413387-5skgsda1jf1q46tu1b0q37j5k6bcvrju.apps.googleusercontent.com',
    GOOGLE_RECAPTCHA_KEY: '6Lff_l0aAAAAAAcjXAIiwGjLcw2QsZh2bcen--FK',
    GOOGLE_ANALYTICS: '',
    ASSIGNATURE_URL:
      'https://gcomstgnew-grupoglobo.cs37.force.com/s/minhaconta?origemId=:origemId&url=:url&_glbid=:glbid',
    ASSIGNATURE_DETAIL_URL:
      'https://selfcare-qa1.sales.qa.globoi.com/pages/assinaturas/:assetId?origemId=:origemId&url=:url&_glbid=:glbid',
    PARENTAL_CONSENT_SERVICE_LOGIN_PAGE:
      'https://consentimentos.qa.globoi.com',
    ORIGIN_ID: 3318,
    MY_FAMILY_URL: 'https://minhafamilia.qa.globoi.com',
    LGPD_LIB:
      'https://s3.glbimg.qa.globoi.com/v1/AUTH_4aa91750485a4e94b956217c8daa570c/lgpd-lib/lgpd-lib.min.js',
    LGPD_LIB_CSS:
      'https://s3.glbimg.qa.globoi.com/v1/AUTH_4aa91750485a4e94b956217c8daa570c/lgpd-lib/lgpd-lib.min.css',
    AVATAR_ID_URL: 'https://avatar.qa.globoi.com',
  },
  dev: {
    ACCOUNT_MANAGEMENT_URL: 'http://minhaconta-v2.dev.globoi.com',
    API_BASE_URL: 'http://api-minhaconta-v2.dev.globoi.com',
    REDIRECT_URL: `https://id.dev.globoi.com/auth/realms/globo.com/protocol/openid-connect/auth?client_id=minha-conta-dev&redirect_uri=${redirectUrl}&response_type=code&scope=openid+email+profile-min&prompt=login`,
    COMPONENTS_URL:
      'https://s3.glbimg.dev.globoi.com/v1/AUTH_ad12aebca3fd41aba150d1ce1f6216dc/globoid-components-container/components.min.js',
    LOGOUT_URL: 'https://login.dev.globoi.com/logout?url=https://www.globo.com',
    FAMILY_URL: 'https://globoid-family-api.dev.globoi.com',
    FACEBOOK_APP_ID: '109800639102711',
    FORGOT_PASSWORD_URL: 'https://login.dev.globoi.com/recuperacaoSenha/7030',
    GLOBO_COM_URL: 'https://www.globo.com',
    GOOGLE_CLIENT_ID:
      '405592413387-5skgsda1jf1q46tu1b0q37j5k6bcvrju.apps.googleusercontent.com',
    GOOGLE_RECAPTCHA_KEY: '6Lff_l0aAAAAAAcjXAIiwGjLcw2QsZh2bcen--FK',
    GOOGLE_ANALYTICS: '',
    ASSIGNATURE_URL:
      'https://gcomevahml-grupoglobo.cs21.force.com/s/eva-redirectminhaconta?origemId=:origemId&url=:url&_glbid=:glbid',
    ASSIGNATURE_DETAIL_URL:
      'https://selfcare-qa1.sales.qa.globoi.com/pages/assinaturas/:assetId?origemId=:origemId&url=:url&_glbid=:glbid',
    PARENTAL_CONSENT_SERVICE_LOGIN_PAGE:
      'https://consentimentos.qa.globoi.com',
    ORIGIN_ID: 3318,
    MY_FAMILY_URL: 'https://minhafamilia.qa.globoi.com',
    LGPD_LIB:
      'https://s3.glbimg.qa.globoi.com/v1/AUTH_4aa91750485a4e94b956217c8daa570c/lgpd-lib/lgpd-lib.min.js',
    LGPD_LIB_CSS:
      'https://s3.glbimg.qa.globoi.com/v1/AUTH_4aa91750485a4e94b956217c8daa570c/lgpd-lib/lgpd-lib.min.css',
    AVATAR_ID_URL: 'https://avatar.qa.globoi.com',
  },
  qa: {
    ACCOUNT_MANAGEMENT_URL: 'https://minhaconta-v2.qa.globoi.com',
    API_BASE_URL: 'https://api-minhaconta-v2.qa.globoi.com',
    REDIRECT_URL: `https://goidc.authentication.qa.globoi.com/auth/realms/globo.com/protocol/openid-connect/auth?client_id=minha-conta-qa-v2@apps.globoid&redirect_uri=${redirectUrl}&response_type=code&scope=openid+email+profile-min&prompt=login`,
    COMPONENTS_URL:
      'https://s3.glbimg.qa.globoi.com/v1/AUTH_211dd4e9382d441cb58f89c7f9242bda/globoid-components-container/components.min.js',
    LOGOUT_URL: 'https://login.qa.globoi.com/logout?url=https://www.globo.com',
    FAMILY_URL: 'https://globoid-family-api.qa.globoi.com',
    FACEBOOK_APP_ID: '1611735559115528',
    FORGOT_PASSWORD_URL: 'https://login.qa.globoi.com/recuperacaoSenha/7030',
    GLOBO_COM_URL: 'https://www.globo.com',
    GOOGLE_CLIENT_ID:
      '281250440561-sobkdbf7geuvnkrkm9rto6f40edelh9b.apps.googleusercontent.com',
    GOOGLE_RECAPTCHA_KEY: '6Lff_l0aAAAAAAcjXAIiwGjLcw2QsZh2bcen--FK',
    GOOGLE_ANALYTICS: 'UA-296593-17',
    ASSIGNATURE_URL:
      'https://gcomstgnew-grupoglobo.cs37.force.com/s/minhaconta?origemId=:origemId&url=:url&_glbid=:glbid',
    ASSIGNATURE_DETAIL_URL:
      'https://selfcare-qa1.sales.qa.globoi.com/pages/assinaturas/:assetId?origemId=:origemId&url=:url&_glbid=:glbid',
    PARENTAL_CONSENT_SERVICE_LOGIN_PAGE:
      'https://consentimentos.qa.globoi.com',
    ORIGIN_ID: 3318,
    MY_FAMILY_URL: 'https://minhafamilia.qa.globoi.com',
    LGPD_LIB:
      'https://s3.glbimg.qa.globoi.com/v1/AUTH_4aa91750485a4e94b956217c8daa570c/lgpd-lib/lgpd-lib.min.js',
    LGPD_LIB_CSS:
      'https://s3.glbimg.qa.globoi.com/v1/AUTH_4aa91750485a4e94b956217c8daa570c/lgpd-lib/lgpd-lib.min.css',
    AVATAR_ID_URL: 'https://avatar.qa.globoi.com',
  },
  prod: {
    ACCOUNT_MANAGEMENT_URL: 'https://minhaconta-v2.globo.com',
    API_BASE_URL: 'https://api-minhaconta-v2.globo.com',
    REDIRECT_URL: `https://goidc.globo.com/auth/realms/globo.com/protocol/openid-connect/auth?client_id=minha-conta-producao-v2@apps.globoid&redirect_uri=${redirectUrl}&response_type=code&scope=openid+email+profile-min&prompt=login`,
    COMPONENTS_URL:
      'https://s3.glbimg.com/v1/AUTH_1e4d71a8f6ee47a9aca424bf41449167/globoid-components-container/components.min.js',
    LOGOUT_URL: 'https://login.globo.com/logout?url=https://www.globo.com',
    FAMILY_URL: 'https://globoid-family-api.globo.com',
    FACEBOOK_APP_ID: '289255557788943',
    FORGOT_PASSWORD_URL: 'https://login.globo.com/recuperacaoSenha/7029',
    GLOBO_COM_URL: 'https://www.globo.com',
    GOOGLE_CLIENT_ID:
      '281250440561-sobkdbf7geuvnkrkm9rto6f40edelh9b.apps.googleusercontent.com',
    GOOGLE_RECAPTCHA_KEY: '6LfPamMaAAAAAOnDKHG__3VIgmalSdnYfR0fX3_R',
    GOOGLE_ANALYTICS: 'UA-296593-17',
    ASSIGNATURE_URL:
      'https://meusprodutos.globo.com/pages/home?origemId=:origemId&url=:url&_glbid=:glbid',
    ASSIGNATURE_DETAIL_URL:
      'https://meusprodutos.globo.com/pages/assinaturas/:assetId?origemId=:origemId&url=:url&_glbid=:glbid',
    PARENTAL_CONSENT_SERVICE_LOGIN_PAGE: 'https://consentimentos.globo.com',
    ORIGIN_ID: 3318,
    MY_FAMILY_URL: 'https://minhafamilia.globo.com',
    LGPD_LIB:
      'https://s3.glbimg.com/v1/AUTH_89c6d9f49eec4e768bc6ccddcb31a34b/lgpd-lib/lgpd-lib.min.js',
    LGPD_LIB_CSS:
      'https://s3.glbimg.com/v1/AUTH_89c6d9f49eec4e768bc6ccddcb31a34b/lgpd-lib/lgpd-lib.min.css',
    AVATAR_ID_URL: 'https://avatar.globo.com',
  },
};

const env = process.env.REACT_APP_ENV || 'prod';
const currentEnv = { ...ENVIRONMENT[env] };
export default currentEnv;
